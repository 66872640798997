import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import IntercomService from '@/services/IntercomService.js';

Vue.use(VueRouter);

const hasPermission = (permission, user, account) => {
  /* NOTE: Account permissions do not make sense since we are removing plan permissions */
  // const found = account.permissions.find((p) => p.title === permission);

  if (user.role === 'Admin') return true;

  return user.permissions.some((p) => p === permission);
};

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: () =>
        import(
          /* webpackChunkName: "home-layout" */ '@/layouts/HomeLayout.vue'
        ),
      children: [
        {
          path: '',
          redirect: 'login',
        },
        {
          path: 'login',
          name: 'login-page',
          component: () =>
            import(
              /* webpackChunkName: "login-page" */ '@/pages/Home/LoginPage.vue'
            ),
        },
        {
          path: 'register',
          name: 'register-page',
          component: () =>
            import(
              /* webpackChunkName: "register-page" */ '@/pages/Home/RegisterPage.vue'
            ),
          props: true,
        },
        {
          path: 'invitation/accept',
          name: 'accept-invitation-page',
          component: () =>
            import(
              /* webpackChunkName: "accept-invitation-page" */ '@/pages/Home/AcceptInvitationPage.vue'
            ),
          props: true,
        },
        {
          path: 'shopify/connect',
          name: 'shopify-connect-page',
          component: () =>
            import(
              /* webpackChunkName: "shopify-connect-page" */ '@/pages/Home/ShopifyConnectPage.vue'
            ),
        },
        {
          path: 'yotpo/connect',
          name: 'yotpo-connect-page',
          component: () =>
          import(
            /* webpackChunkName: "yotpo-connect-page" */ '@/pages/Home/YotpoConnectPage.vue'
          ),
          meta: {
            requiresAuth: false
          },
        },
        {
          path: '/surveys/:surveyId',
          name: 'survey-page',
          component: () =>
            import(
              /* webpackChunkName: "survey-page" */ '@/pages/Home/SurveyPage.vue'
            ),
        },
        {
          path: 'select-account',
          name: 'select-account-page',
          props: { dark: true },
          component: () =>
            import(
              /* webpackChunkName: "select-account-page" */ '@/pages/Main/UserSettings/UserAccountsPage.vue'
            ),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: 'add-account',
          name: 'add-account-page',
          component: () =>
            import(
              /* webpackChunkName: "select-account-page" */ '@/pages/Home/RegisterPage.vue'
            ),
        },
        {
          path: 'forgot-password',
          name: 'forgot-password-page',
          component: () =>
            import(
              /* webpackChunkName: "forgot-password-page" */ '@/pages/Home/ForgotPasswordPage.vue'
            ),
          props: true,
        },
        {
          path: 'password-reset/:token',
          name: 'password-reset-page',
          component: () =>
            import(
              /* webpackChunkName: "password-reset-page" */ '@/pages/Home/PasswordResetPage.vue'
            ),
          props: true,
        },
        {
          path: 'subscription',
          name: 'subscription-page',
          component: () =>
            import(
              /* webpackChunkName: "subscription-page" */ '@/pages/SubscriptionPage.vue'
            ),
          meta: {
            requiresAuth: true,
          },
        },
      ],
    },
    {
      path: '/benchmarks',
      name: 'benchmarks-page',
      component: () =>
        import(
          /* webpackChunkName: "global-benchmarks-page" */ '@/pages/Benchmarks/index.vue'
        ),
    },
    {
      path: '/main',
      component: () =>
        import(
          /* webpackChunkName: "main-layout" */ '@/layouts/MainLayout.vue'
        ),
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: 'dashboard',
          name: 'dashboard-page',
          component: () =>
            import(
              /* webpackChunkName: "Dashboard Page" */ '@/pages/Main/Dashboard.vue'
            ),
          props: true,
        },
        {
          path: 'data',
          component: () =>
            import(
              /* webpackChunkName: "data-layout" */ '@/pages/Main/Data/DataLayout.vue'
            ),
          children: [
            {
              path: 'surveys/flow',
              name: 'data-survey-flow-page',
              meta: { permission: 'SURVEYS' },
              component: () =>
                import(
                  /* webpackChunkName: "data-survey-flow-page" */ '@/pages/Main/Data/SurveyFlow/SurveyFlow.vue'
                ),
            },
            {
              path: 'surveys/flow/:surveyId',
              name: 'data-survey-flow-page-edit',
              meta: { permission: 'SURVEYS' },
              props: true,
              component: () =>
                import(
                  /* webpackChunkName: "data-survey-flow-page" */ '@/pages/Main/Data/SurveyFlow/SurveyFlow.vue'
                ),
            },
            {
              path: 'questions',
              name: 'data-questions-page',
              component: () =>
                import(
                  /* webpackChunkName: "create-question-page" */ '@/pages/Main/Data/QuestionsPage.vue'
                ),
            },
            {
              path: 'questions/create',
              name: 'data-create-question-page',
              props: {
                source: 'account',
              },
              component: () =>
                import(
                  /* webpackChunkName: "create-question-page" */ '@/pages/Admin/Questions/CreateQuestionPage.vue'
                ),
            },
            {
              path: 'questions/:questionId',
              name: 'data-edit-question-page',
              props: {
                source: 'account',
              },
              component: () =>
                import(
                  /* webpackChunkName: "create-question-page" */ '@/pages/Admin/Questions/CreateQuestionPage.vue'
                ),
            },
            {
              path: 'question/:questionId/preview',
              name: 'data-preview-question-page',
              meta: {
                hideLayout: true,
              },
              props: {
                source: 'account',
              },
              component: () =>
                import(
                  /* webpackChunkName: "preview-question-page" */ '@/pages/Admin/Questions/PreviewQuestionPage.vue'
                ),
            },
            {
              path: '',
              name: 'data-reports-page',
              // meta: { permission: 'REPORTS' },
              component: () =>
                import(
                  /* webpackChunkName: "data-reports-page" */ '@/pages/Main/Data/ReportsPage.vue'
                ),
            },
            {
              path: 'surveys',
              name: 'data-surveys-page',
              meta: { permission: 'SURVEYS' },
              component: () =>
                import(
                  /* webpackChunkName: "data-surveys-page" */ '@/pages/Main/Data/Surveys/SurveysPage.vue'
                ),
            },
            {
              path: 'surveys/:surveyId/preview',
              name: 'data-preview-survey-page',
              meta: {
                hideLayout: true,
                permission: 'SURVEYS',
              },
              component: () =>
                import(
                  /* webpackChunkName: "data-preview-survey-page" */ '@/pages/Main/Data/Surveys/PreviewSurveyPage.vue'
                ),
            },
            {
              path: 'surveys/:surveyId/responses',
              name: 'data-survey-responses-page',
              meta: { permission: 'SURVEYS' },
              component: () =>
                import(
                  /* webpackChunkName: "data-survey-responses-page" */ '@/pages/Main/Data/SurveyResponsesPage.vue'
                ),
            },
            {
              path: 'audiences',
              name: 'data-audiences-page',
              meta: { permission: 'SEGMENTS' },
              component: () =>
                import(
                  /* webpackChunkName: "data-segments-page" */ '@/pages/Main/Data/SegmentsPage.vue'
                ),
            },
          ],
        },
        {
          path: 'reports',
          component: () =>
            import(
              /* webpackChunkName: "reports-layout" */ '@/pages/Main/Reports/ReportsLayout.vue'
            ),
          children: [
            {
              path: 'attribution',
              name: 'reports-attribution-page',
              component: () =>
                import(
                  /* webpackChunkName: "reports-attribution-page" */ '@/pages/Main/Reports/Insights/Attribution/Dashboard.vue'
                ),
            },
            {
              path: 'benchmarks',
              name: 'reports-benchmarks-page',
              component: () =>
                import(
                  /* webpackChunkName: "create-benchmarks-page" */ '@/pages/Main/Reports/Insights/Benchmarks/Dashboard.vue'
                ),
            },
          ],
        },
        {
          path: 'automated-insights',
          component: () =>
            import(
              /* webpackChunkName: "data-layout" */ '@/pages/Main/AutomatedInsights/AutomatedInsightsLayout.vue'
            ),
          children: [
            {
              path: 'reports',
              name: 'automated-insights-page',
              meta: { permission: 'REPORTS' },
              component: () =>
                import(
                  /* webpackChunkName: "automated-insights-page" */ '@/pages/Main/AutomatedInsights/AutomatedInsightsReports.vue'
                ),
            },
            {
              path: 'settings',
              name: 'automated-insights-settings',
              meta: { permission: 'REPORTS' },
              component: () =>
                import(
                  /* webpackChunkName: "automated-insights-settings" */ '@/pages/Main/AutomatedInsights/AutomatedInsightsSettings.vue'
                ),
            },
          ],
        },
        {
          path: 'settings',
          component: () =>
            import(
              /* webpackChunkName: "settings-layout" */ '@/pages/Main/Settings/SettingsLayout.vue'
            ),
          children: [
            {
              path: '',
              name: 'settings-page',
              component: () =>
                import(
                  /* webpackChunkName: "settings-account-page" */ '@/pages/Main/Settings/SettingsPage.vue'
                ),
            },
            {
              path: 'account',
              name: 'settings-account-page',
              meta: { permission: 'ACCOUNT' },
              component: () =>
                import(
                  /* webpackChunkName: "settings-account-page" */ '@/pages/Main/Settings/AccountPage.vue'
                ),
            },
            {
              path: 'api-clients',
              name: 'settings-api-clients-page',
              meta: { permission: 'API_CLIENTS' },
              component: () =>
                import(
                  /* webpackChunkName: "settings-api-clients-page" */ '@/pages/Main/Settings/ApiClientsPage.vue'
                ),
            },
            {
              path: 'billing',
              name: 'settings-billing-page',
              meta: { permission: 'BILLING' },
              component: () =>
                import(
                  /* webpackChunkName: "settings-billing-page" */ '@/pages/Main/Settings/BillingPage.vue'
                ),
            },
            {
              path: 'embed',
              name: 'settings-embed-page',
              meta: { permission: 'EMBED' },
              component: () =>
                import(
                  /* webpackChunkName: "settings-embed-page" */ '@/pages/Main/Settings/EmbedPage.vue'
                ),
            },
            {
              path: 'integrations',
              name: 'settings-integrations-page',
              meta: { permission: 'INTEGRATIONS' },
              component: () =>
                import(
                  /* webpackChunkName: "settings-integrations-page" */ '@/pages/Main/Settings/Integrations/IntegrationsPage.vue'
                ),
            },
            {
              path: 'integrations/custom',
              meta: { permission: 'INTEGRATIONS' },
              name: 'settings-custom-integration-page',
              component: () =>
                import(
                  /* webpackChunkName: "settings-custom-integration-page" */ '@/pages/Main/Settings/Integrations/CustomIntegrationPage.vue'
                ),
            },
            {
              path: 'integrations/klaviyo',
              meta: { permission: 'INTEGRATIONS' },
              name: 'settings-klaviyo-integration-page',
              component: () =>
                import(
                  /* webpackChunkName: "settings-klaviyo-integration-page" */ '@/pages/Main/Settings/Integrations/KlaviyoIntegrationPage.vue'
                ),
            },
            {
              path: 'integrations/peel',
              meta: { permission: 'INTEGRATIONS' },
              name: 'settings-peel-integration-page',
              component: () =>
                import(
                  /* webpackChunkName: "settings-peel-integration-page" */ '@/pages/Main/Settings/Integrations/PeelIntegrationPage.vue'
                ),
            },
            {
              path: 'integrations/rockerbox',
              meta: { permission: 'INTEGRATIONS' },
              name: 'settings-rockerbox-integration-page',
              component: () =>
                import(
                  /* webpackChunkName: "settings-rockerbox-integration-page" */ '@/pages/Main/Settings/Integrations/RockerboxIntegrationPage.vue'
                ),
            },
            {
              path: 'integrations/shopify',
              meta: { permission: 'INTEGRATIONS' },
              name: 'settings-shopify-integration-page',
              component: () =>
                import(
                  /* webpackChunkName: "settings-shopify-integration-page" */ '@/pages/Main/Settings/Integrations/ShopifyIntegrationPage.vue'
                ),
            },
            {
              path: 'integrations/shopify_flow',
              meta: { permission: 'INTEGRATIONS' },
              name: 'settings-shopify-flow-integration-page',
              component: () =>
                import(
                  /* webpackChunkName: "settings-shopify-flow-integration-page" */ '@/pages/Main/Settings/Integrations/ShopifyFlowIntegrationPage.vue'
                ),
            },
            {
              path: 'integrations/yotpo',
              meta: { permission: 'INTEGRATIONS' },
              name: 'settings-yotpo-integration-page',
              component: () =>
                import(
                  /* webpackChunkName: "settings-yotpo-integration-page" */ '@/pages/Main/Settings/Integrations/YotpoIntegrationPage.vue'
                ),
            },
            {
              path: 'integrations/show',
              meta: { permission: 'INTEGRATIONS' },
              name: 'settings-integration-show-page',
              props: true,
              component: () =>
                import(
                  /* webpackChunkName: "settings-integration-show-page" */ '@/pages/Main/Settings/Integrations/IntegrationShowPage.vue'
                ),
            },
            {
              path: 'integrations/triplewhale',
              meta: { permission: 'INTEGRATIONS' },
              name: 'settings-triplewhale-integration-page',
              component: () =>
                import(
                  /* webpackChunkName: "settings-triplewhale-integration-page" */ '@/pages/Main/Settings/Integrations/TripleWhaleIntegrationPage.vue'
                ),
            },
            {
              path: 'integrations/klar',
              meta: { permission: 'INTEGRATIONS' },
              name: 'settings-klar-integration-page',
              component: () =>
                import(
                  /* webpackChunkName: "settings-klar-integration-page" */ '@/pages/Main/Settings/Integrations/KlarIntegrationPage.vue'
                ),
            },
            {
              path: 'integrations/daasity',
              meta: { permission: 'INTEGRATIONS' },
              name: 'settings-daasity-integration-page',
              component: () =>
                import(
                  /* webpackChunkName: "settings-daasity-integration-page" */ '@/pages/Main/Settings/Integrations/DaasityIntegrationPage.vue'
                ),
            },
            {
              path: 'integrations/yotpo',
              meta: { permission: 'INTEGRATIONS' },
              name: 'settings-yotpo-integration-page',
              component: () =>
                import(
                  /* webpackChunkName: "settings-yotpo-integration-page" */ '@/pages/Main/Settings/Integrations/YotpoIntegrationPage.vue'
                ),
            },
            {
              path: 'integrations/show',
              meta: { permission: 'INTEGRATIONS' },
              name: 'settings-integration-show-page',
              props: true,
              component: () =>
                import(
                  /* webpackChunkName: "settings-integration-show-page" */ '@/pages/Main/Settings/Integrations/IntegrationShowPage.vue'
                ),
            },
            {
              path: 'users',
              name: 'settings-users-page',
              meta: { permission: 'USERS' },
              component: () =>
                import(
                  /* webpackChunkName: "settings-users-page" */ '@/pages/Main/Settings/UsersPage.vue'
                ),
            },
            {
              path: 'api-clients',
              name: 'settings-api-clients-page',
              meta: { permission: 'API_CLIENTS' },
              component: () =>
                import(
                  /* webpackChunkName: "settings-api-clients-page" */ '@/pages/Main/Settings/ApiClientsPage.vue'
                ),
            },
          ],
        },
        {
          path: 'offers',
          name: 'offers-page',
          component: () =>
            import(
              /* webpackChunkName: "offers-page" */ '@/pages/Main/Offers.vue'
            ),
        },
        {
          path: 'user-settings',
          name: 'user-settings',
          component: () =>
            import(
              /* webpackChunkName: "user-settings" */ '@/pages/Main/UserSettings/UserSettingsLayout.vue'
            ),
          children: [
            {
              path: 'accounts',
              name: 'user-accounts-page',
              component: () =>
                import(
                  /* webpackChunkName: "user-accounts-page" */ '@/pages/Main/UserSettings/UserAccountsPage.vue'
                ),
            },
            {
              path: 'profile',
              name: 'user-profile-page',
              component: () =>
                import(
                  /* webpackChunkName: "user-profile-page" */ '@/pages/Main/UserSettings/UserProfilePage.vue'
                ),
            },
            {
              path: 'reports',
              name: 'user-reports-page',
              component: () =>
                import(
                  /* webpackChunkName: "user-reports-page" */ '@/pages/Main/Data/ReportsPage.vue'
                ),
            },
          ],
        },
        {
          path: 'redirect',
          name: 'redirect-page',
          component: () =>
            import(
              /* webpackChunkName: "redirect-page" */ '@/pages/Main/RedirectPage.vue'
            ),
        },
        {
          path: 'complete',
          name: 'complete-page',
          component: () =>
            import(
              /* webpackChunkName: "redirect-page" */ '@/pages/Main/CompleteProfilePage.vue'
            ),
        },
        {
          path: 'subscribe',
          name: 'subscribe-page',
          component: () =>
            import(
              /* webpackChunkName: "redirect-page" */ '@/pages/Main/SubscribePage.vue'
            ),
        },
      ],
    },
    {
      path: '/admin',
      component: () =>
        import(
          /* webpackChunkName: "main-layout" */ '@/layouts/MainLayout.vue'
        ),
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: '',
          redirect: 'accounts',
        },
        {
          path: 'accounts',
          name: 'accounts-page',
          component: () =>
            import(
              /* webpackChunkName: "accounts-page" */ '@/pages/Admin/AccountsPage.vue'
            ),
        },
        {
          path: 'templates',
          name: 'templates-page',
          component: () =>
            import(
              /* webpackChunkName: "templates-page" */ '@/pages/Admin/Templates/TemplatesPage.vue'
            ),
        },
        {
          path: 'templates/create',
          name: 'create-template-page',
          component: () =>
            import(
              /* webpackChunkName: "create-template-page" */ '@/pages/Admin/Templates/CreateTemplatePage.vue'
            ),
        },
        {
          path: 'templates/:templateId',
          name: 'edit-template-page',
          component: () =>
            import(
              /* webpackChunkName: "edit-template-page" */ '@/pages/Admin/Templates/CreateTemplatePage.vue'
            ),
        },
        {
          path: 'templates/:templateId/preview',
          name: 'preview-template-page',
          meta: {
            hideLayout: true,
          },
          component: () =>
            import(
              /* webpackChunkName: "preview-template-page" */ '@/pages/Admin/Templates/PreviewTemplatePage.vue'
            ),
        },
        {
          path: 'segment-templates',
          name: 'segment-templates-page',
          component: () =>
            import(
              /* webpackChunkName: "segment-templates-page" */ '@/pages/Admin/SegmentTemplates/SegmentTemplatesPage.vue'
            ),
        },
        {
          path: 'segment-templates/create',
          name: 'create-segment-template-page',
          component: () =>
            import(
              /* webpackChunkName: "create-segment-template-page" */ '@/pages/Admin/SegmentTemplates/CreateSegmentTemplatePage.vue'
            ),
        },
        {
          path: 'segment-templates/:templateId',
          name: 'edit-segment-template-page',
          component: () =>
            import(
              /* webpackChunkName: "edit-segment-template-page" */ '@/pages/Admin/SegmentTemplates/CreateSegmentTemplatePage.vue'
            ),
        },
        {
          path: 'questions',
          name: 'questions-page',
          component: () =>
            import(
              /* webpackChunkName: "questions-page" */ '@/pages/Admin/Questions/QuestionsPage.vue'
            ),
        },
        {
          path: 'reports',
          name: 'reports-page',
          component: () =>
            import(
              /* webpackChunkName: "reports-page" */ '@/pages/Admin/ReportsPage.vue'
            ),
        },
        {
          path: 'question/create',
          name: 'create-question-page',
          props: {
            source: 'global',
          },
          component: () =>
            import(
              /* webpackChunkName: "create-question-page" */ '@/pages/Admin/Questions/CreateQuestionPage.vue'
            ),
        },
        {
          path: 'question/:questionId',
          name: 'edit-question-page',
          props: {
            source: 'global',
          },
          component: () =>
            import(
              /* webpackChunkName: "edit-question-page" */ '@/pages/Admin/Questions/CreateQuestionPage.vue'
            ),
        },
        {
          path: 'question/:questionId/preview',
          name: 'preview-question-page',
          meta: {
            hideLayout: true,
          },
          props: {
            source: 'global',
          },
          component: () =>
            import(
              /* webpackChunkName: "preview-question-page" */ '@/pages/Admin/Questions/PreviewQuestionPage.vue'
            ),
        },
        {
          path: 'permissions',
          name: 'permissions-page',
          component: () =>
            import(
              /* webpackChunkName: "permissions-page" */ '@/pages/Admin/PermissionsPage.vue'
            ),
        },
        {
          path: 'plans',
          name: 'plans-page',
          component: () =>
            import(
              /* webpackChunkName: "plans-page" */ '@/pages/Admin/PlansPage.vue'
            ),
        },
        { // DEPRECATED:
          path: 'promotions',
          name: 'promo-codes-page',
          component: () =>
            import(
              /* webpackChunkName: "promo-codes-page" */ '@/pages/Admin/PromoCodesPage.vue'
            ),
        },
        {
          path: 'partner-offers',
          name: 'partner-offers-page',
          component: () =>
            import(
              /* webpackChunkName: "partner-offers-page" */ '@/pages/Admin/PartnerOffersPage.vue'
            ),
        },
        { // DEPRECATED:
          path: 'test',
          name: 'test-page',
          component: () =>
            import(
              /* webpackChunkName: "survey-page" */ '@/pages/Admin/TestPage.vue'
            ),
        },
        {
          path: 'change-account-payment',
          name: 'change-account-payment-page',
          component: () =>
            import(
              /* webpackChunkName: "change-account-payment-page" */ '@/pages/Admin/ChangeAccountPaymentPage.vue'
            ),
        },
        {
          path: 'clone-account-survey',
          name: 'clone-account-survey-page',
          component: () =>
            import(
              /* webpackChunkName: "clone-account-survey-page" */ '@/pages/Admin/CloneAccountSurveyPage.vue'
            ),
        },
        { // DEPRECATED
          path: 'tiktok-dashboard-view/:accountId',
          name: 'tiktok-dashboard-view',
          component: () =>
          import(
            /* webpackChunkName: "tiktok-dashboard-page" */ '@/pages/Main/TiktokDashboard.vue'
          ),
        },
      ],
    },
    { // DEPRECATED
      path: '/tiktok',
      component: () =>
        import(
        /* webpackChunkName: "tiktok-signup-layout" */ '@/layouts/TiktokSignupLayout.vue'
        ),
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: 'connect',
          name: 'tiktok-connect-page',
          component: () =>
            import(
            /* webpackChunkName: "tiktok-connect-page" */ '@/pages/TikTok/TiktokConnectPage.vue'
            ),
          meta: {
            requiresAuth: false
          },
        },
        {
          path: 'survey/preview',
          name: 'tiktok-survey-page',
          component: () =>
            import(
            /* webpackChunkName: "tiktok-survey-page" */ '@/pages/TikTok/TiktokSurveyPage.vue'
            ),
          meta: {
            requiresAuth: true,
          },
        },
      ],
    },
    { // DEPRECATED:
      path: '/tiktok-main',
      component: () =>
        import(
        /* webpackChunkName: "tiktok-main-layout" */ '@/layouts/TiktokMainLayout.vue'
        ),
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: 'dashboard',
          name: 'tiktok-dashboard-page',
          component: () =>
            import(
            /* webpackChunkName: "tiktok-dashboard-page" */ '@/pages/Main/TiktokDashboard.vue'
            ),
          props: true,
        },
      ],
    },
    {
      path: '*',
      redirect: '/',
    },
  ],
});

const PUBLIC_ROUTES = new Set([
  'accept-invitation-page',
  'password-reset-page',
  'survey-page',
  'benchmarks-page',
  'shopify-connect-page',
  'tiktok-connect-page',  // DEPRECATED:
  'yotpo-connect-page',
]);

const SUPER_ADMIN_ALLOWED_ROUTES = new Set([
  'partner-offers-page',
  'promo-codes-page', // DEPRECATED:
  'accounts-page',
  'templates-page',
  'create-template-page',
  'edit-template-page',
  'preview-template-page',
  'segment-templates-page',
  'create-segment-template-page',
  'edit-segment-template-page',
  'preview-segment-template-page',
  'questions-page',
  'reports-page',
  'create-question-page',
  'edit-question-page',
  'preview-question-page',
  'plans-page',
  'permissions-page',
  'change-account-payment-page',
  'clone-account-survey-page',
  'test-page', // DEPRECATED:
  'automated-insights-page',
  'tiktok-dashboard-view', // DEPRECATED:
]);

const USER_ALLOWED_ROUTES = new Set([
  'offers-page',
  'dashboard-page',
  'data-reports-page',
  'data-preview-question-page',
  'data-edit-question-page',
  'data-create-question-page',
  'data-questions-page',
  'data-surveys-page',
  'data-create-survey-page',
  'data-edit-survey-page',
  'data-preview-survey-page',
  'data-survey-responses-page',
  'data-survey-flow-page',
  'data-survey-flow-page-edit',
  'data-audiences-page',
  'settings-page',
  'settings-account-page',
  'settings-billing-page',
  'settings-embed-page',
  'settings-users-page',
  'settings-api-clients-page',
  'settings-integrations-page',
  'settings-custom-integration-page',
  'settings-integration-show-page',
  'settings-peel-integration-page',
  'settings-triplewhale-integration-page',
  'settings-rockerbox-integration-page',
  'settings-shopify-integration-page',
  'settings-shopify-flow-integration-page',
  'settings-klaviyo-integration-page',
  'settings-klar-integration-page',
  'settings-daasity-integration-page',
  'user-accounts-page',
  'user-profile-page',
  'user-reports-page',
  'select-account-page',
  'add-account-page',
  'redirect-page',
  'complete-page',
  'subscribe-page',
  'subscription-page',
  'automated-insights-page',
  'automated-insights-settings',
  'reports-attribution-page',
  'reports-benchmarks-page',
  'tiktok-survey-page', // DEPRECATED:
  'settings-yotpo-integration-page',
]);

// DEPRECATED:
const TIKTOK_USER_ALLOWED_ROUTES = new Set([
  'login-page',
  'select-account-page',
  'tiktok-connect-page',
  'shopify-connect-page',
]);

const redirectWhiteLabelUser = (name, accountData, tiktokConnectState) => {

  const hasIntegrations = accountData?.hasTiktok && accountData?.hasShopify;

  console.log(`
    authenticatedRouteMiddleware
    - page allowed ${TIKTOK_USER_ALLOWED_ROUTES.has(name)}
    - completedAt ${tiktokConnectState?.completedAt}
    - hasIntegrations ${hasIntegrations}
    - hasShopify ${accountData?.hasShopify}
    - hasTiktok ${accountData?.hasTiktok}
  `);

  if (TIKTOK_USER_ALLOWED_ROUTES.has(name)) {
    console.log('TT redirecting from router to allowed page...', name);
    return name;
  }

  if (!TIKTOK_USER_ALLOWED_ROUTES.has(name) || !hasIntegrations) {
    const { advertiserId } = tiktokConnectState || {};
    console.log('TT redirect advertiserId', advertiserId, name, hasIntegrations);
    if (advertiserId) {
      console.log('TT redirecting from router to whitelabel...');
      $helpers.logout($helpers.getWhiteLabelRedirect(advertiserId));
    }
  }

  console.log('TT redirecting from router to tiktok-connect-page...');

  return 'tiktok-connect-page';
}

const authenticatedRouteMiddleware = (to, next) => {

  const { userData, accountData, tiktokConnectState } = store.state.authStore;
  const { plan } = store.state.appStore;

  if (!userData) return next();

  if (userData.isSuperAdmin) {
    // Default home page for super admins
    if (!SUPER_ADMIN_ALLOWED_ROUTES.has(to.name)) {
      return next({ name: 'accounts-page' });
    }
  } else if (plan?.lookupKey === 'tiktok-free') {
    console.log('whitelabel plan ... redirecting to tiktok connect');
    const page = redirectWhiteLabelUser(to.name, accountData, tiktokConnectState);
    console.log('whitelabel plan ... redirecting to page', page);
    if (page) return next({ name: page});
  } else {
    // Default to home page for regular users
    if (!USER_ALLOWED_ROUTES.has(to.name)) {
      return next({ name: 'dashboard-page' });
    }
  }

  console.log('default next', to.name);
  return next();
};

const routeAuthenticatedUser = (to, next) => {

  const { accountData, userData } = store.state.authStore;
  const { plan } = store.state.appStore;

  /* Reset upgrade modal state to prevent issues (Fixed if we get rid of persistent state) */
  store.dispatch('appStore/changeUpgradeModal', { show: false });

  // Don't bother the super admin
  if (userData.isSuperAdmin) {
    return authenticatedRouteMiddleware(to, next);
  }

  IntercomService.methods.changedURL();

  // If there is no account selected
  if (!accountData || !accountData.id) {
    if (to.name !== 'select-account-page' && to.name !== 'add-account-page') {
      return next({ name: 'select-account-page' });
    }
    return authenticatedRouteMiddleware(to, next);
  }

  // If user has not completed registration process
  if (!accountData.planId && !$helpers.isAccountCompleted(accountData)) {
    if (to.name !== 'complete-page') {
      return next({ name: 'complete-page' });
    }

    return next();
  }

  // If user has not selected a plan or billing status is not
  // active or trialing
  if (
    !accountData.planId ||
    !$helpers.isAccountStatusActive(accountData, plan)
  ) {
    if (to.name !== 'subscription-page') {
      return next({ name: 'subscription-page' });
    }
    return next();
  }

  // Default to home page if user already has a plan selected
  // and route does not require auth
  if (
    accountData.planId &&
    !to.matched.some((record) => record.meta.requiresAuth)
  ) {
    return next({
      name: userData.isSuperAdmin ? 'accounts-page' : 'dashboard-page',
    });
  }

  // Allow accounts canceled sub to go to settings billing page
  if (
    to.meta.permission === 'BILLING' &&
    accountData.planId === null &&
    accountData.subscription === null
  ) {
    return authenticatedRouteMiddleware(to, next);
  }

  // Redirect users If user does not have premissions to visit route
  if (
    to.meta.permission &&
    !hasPermission(to.meta.permission, userData, accountData)
  ) {
    return next({ name: 'data-reports-page' });
  }

  // Show upgrade modal if user has no access only on beta
  if (process.env.VUE_APP_BETA === 'true' && !plan?.allowBetaAccess) {

    store.dispatch('appStore/changeUpgradeModal', {
      show: true,
      type: 'BETA_NOT_ALLOWED',
      persistent: true,
    });
  }

  return authenticatedRouteMiddleware(to, next);
};

router.beforeEach((to, _from, next) => {

  if (PUBLIC_ROUTES.has(to.name)) {
    return next();
  }

  if ($helpers.isAuthenticated()) {
    return routeAuthenticatedUser(to, next);
  }

  // If route requires authenticate
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    return next({ name: 'login-page' });
  }

  return next();
});

export default router;
